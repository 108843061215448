import React from "react";
import { graphql } from "gatsby";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import Image from "../components/Image";
import { relativeToAbsulote } from "../util/common";
import Layout from "../components/layout";
import { LogoHeader } from "../components/header";
import HubspotForm from "react-hubspot-form";
import ResourceTag from "../components/resource-tag";
import Twitter from "../images/twitter.svg";
import Linkedin from "../images/linkedin.svg";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import BreadCrumbs from "../components/resources/breadcrumbs";

function WhitePaperTemplate({
  data: { node, site },
  pageContext: { isThankYou },
  location,
}) {
  const { path, relationships } = node;
  const { left, hubspot, thankyou, pdf } = relationships;

  const Og = node?.relationships?.OGImage?.localFile?.publicURL;
  const seo = getMetatags(node.metatag, Og);
  const type = "Ebook";

  if (!isThankYou) {
    return (
      <Layout showCta={false}>
        <Seo {...seo} />
        <div className="h-20 lg:h-28"></div>

        <div className="container mx-auto  lg:pb-28 space-y-32 lg:space-y-0 ">
          <div className="container  flex lg:flex-row flex-col lg:space-x-20 lg:mb-32 mb-12 lg:items-start ">
            <div className="lg:w-1/2 -h-full	-min-h-screen lg:px-8">
              <HeaderHero
                title={node.title}
                type={type}
                location={location}
                base={site.siteMetadata.siteUrl}
              />
              <Main left={left} />
            </div>
            <div className={"lg:w-1/2  text-white 	 lg:px-12 lg:py-6 "}>
              <Side {...hubspot} path={path.alias} />
            </div>
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout showCta={false}>
        <Seo {...seo} />
        <div className="flex flex-col lg:flex-row w-full -h-full -min-h-screen">
          <div className="lg:w-1/2 -h-full	-min-h-screen lg:px-8">
            <LogoHeader />
            <MainThankYou thankyou={thankyou} />
          </div>
          <div
            className={
              "lg:w-1/2  text-white 	 lg:px-12 lg:py-6 lg:bg-[url('/background/BookADemo.png')] bg-no-repeat bg-top lg:pt-8 "
            }
          >
            <SideThankYou thankyou={thankyou} pdf={pdf} />
          </div>
        </div>
      </Layout>
    );
  }
}

const Side = ({ portal_id, form_id, title, sub_title, path /* , mode */ }) => {
  const ready = (form) => {
    var containers = document.getElementsByClassName("legal-consent-container");

    for (var x = 0; x < containers.length; x++) {
      var container = containers[x];

      var fieldset1 = container.closest("fieldset");
      var f = container.closest("form");

      f.append(fieldset1);
    }
  };

  return (
    <div className={"container mt-8 lg:mt-20"}>
      <div
        className={"bg-white text-grey  rounded-xl py-6 px-10 border  "}
        style={{ boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)" }}
      >
        <h2 className="text-blue  text-2xl lg:subtitle-m-bold font-bold py-2">
          {title}
        </h2>
        <p className="py-4 text-sm">{sub_title}</p>
        <HubspotForm
          portalId={portal_id}
          formId={form_id}
          //cssRequired=""
          css=" " // !important
          cssClass="sigthfull-hubspot"
          submitButtonClass="sigthfull-hubspot-submit-btn c-btn"
          errorClass="sigthfull-hubspot-error"
          errorMessageClass="sigthfull-hubspot-error-message"
          //onSubmit={(e) => console.log('Submit!')}
          //onFromSubmit={(e) => console.log('on form submit')}
          //onFormSubmitted={(e) => console.log('on onFormSubmitted')}

          onReady={ready}
          //loading={<div>Loading...</div>}
          redirectUrl={path + "/thank-you"}
        />
      </div>
    </div>
  );
};

const Main = ({ left }) => {
  //console.log(left)
  return (
    <div className="">
      <h2
        className=" text-[24px] lg:text-[24px] leading-[36px] font-bold  lg:py-4 py-2"
        dangerouslySetInnerHTML={{ __html: left.title }}
      ></h2>
      <div className="text-2xl font-bold">{left?.label}</div>
      {/* <Image image={left.relationships.image} alt={left.image.alt} className="py-8" /> */}
      <div
        className="blog-post"
        dangerouslySetInnerHTML={{
          __html: relativeToAbsulote(left?.description_html?.processed),
        }}
      ></div>

      <ul className="blog-post list-none list-inside pb-0">
        {left.bullets.map((s, index) => (
          <li className="pb-3" key={index}>
            <span className="text-lg lg:text-2xl">✓</span> {s}
          </li>
        ))}
      </ul>
      <p className="text-base lg:text-xl	lg:leading-8 pb-1">
        {left.description}
      </p>
    </div>
  );
};

const MainThankYou = ({ thankyou }) => {
  return (
    <div className="lg:pt-12 lg:px-12 pt-8 px-8 pb-24 ">
      <h1 className="text-5xl py-8 leading-[3.5rem]">{thankyou.title}</h1>
      <div className="text-3xl py-8 leading-[3.5rem]">{thankyou.label}</div>
      {/* <p className="text-3xl pb-12 font-bold">{thankyou.description}</p>
      <div className="text-2xl pb-4" dangerouslySetInnerHTML={{ __html: relativeToAbsulote(thankyou?.description_html?.processed) }} /> */}
      <Image image={thankyou.relationships.image} alt={thankyou.image.alt} />
    </div>
  );
};

const SideThankYou = ({ thankyou, pdf }) => {
  return (
    <div className="container  lg:pt-32 p-3  ">
      <div
        className="bg-white text-grey py-12 px-10 rounded-xl border "
        style={{ boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)" }}
      >
        <div className="lg:bg-[url('/background/download.png')] bg-no-repeat bg-top h-[500px]"></div>

        <div className="text-center w-full ">
          <a
            href={pdf.localFile.publicURL}
            target="__blank"
            className=" text-white font-bold py-3 px-5 text-xl bg-blue  block rounded-full"
          >
            {thankyou.cta.title}
          </a>
        </div>
      </div>
    </div>
  );
};

const HeaderHero = ({ title, type, location, base }) => {
  return (
    <div>
      <div>
        <BreadCrumbs title={title} base={base} />
      </div>
      <div className="pb-4">
        <ResourceTag type={type} className="" url="/resources/" />
      </div>
      <div>
        <h1 className="text-[32px] lg:text-[40px] leading-[40px] ">{title}</h1>
      </div>
      <div className="flex flex-col lg:flex-row -lg:justify-between my-4 space-y-4 -lg:space-y-0 ">
        <div className="flex flex-row space-x-2 my-4">
          <LinkedinShareButton url={location.href}>
            <img src={Linkedin} alt="Linkedin" width={18} height={18} />
          </LinkedinShareButton>

          <TwitterShareButton url={location.href}>
            <img src={Twitter} alt="Twitter" width={18} height={18} />
          </TwitterShareButton>
        </div>
      </div>
      <div className="h-1 border-b border-gray-600 w-full mt-4 mb-8 -lg:my-10"></div>
    </div>
  );
};

export default WhitePaperTemplate;

export const query = graphql`
  query whitePaper($id: String!) {
    node: nodeWhitePaper(id: { eq: $id }) {
      id
      nid: drupal_internal__nid
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      image: field_hero_image {
        alt
      }

      relationships {
        image: field_hero_image {
          ...Image
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
        pdf: field_pdf_file {
          localFile {
            publicURL
          }
        }
        thankyou: field_paragraph_single {
          ...ParagraphGenericBlock
        }
        left: field_content_block {
          ...ParagraphGenericBlock
        }
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
